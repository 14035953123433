/* ImageModal.css */

/* .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    position: relative;
  } */
  
  /* .modal-image {
    max-width: 90%;
    max-height: 90%;
    border-radius: 8px;
  } */
  
  /* .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    color: white;
    font-size: 45px;
    cursor: pointer;
  } */
  

  .conversation .modal-content {
    width: 86%;
    position: absolute;
    background: #fff;
    text-align: center;
    border-radius: 10px;
    overflow: hidden;
}

.conversation .modal-image {
  max-width: 100%;
  max-height: 90%;
  border-radius: 0;
}
.conversation .close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #b50f58;
  border: none;
  color: #fff;
  font-size: 45px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  line-height: 40px;
}

.conversation .modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  z-index: 9999999;
}